import { useEffect, useState } from "react";

export default function useRerenderOnce() {
  /* eslint-disable */
  const [_, s] = useState();

  useEffect(() => {
    s(true);
  }, []);
}

import React, { useState, useRef } from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"
import useWindowSize from "../../utils/use-window-size"
import useRerenderOnce from "../../utils/use-rerender-once"

function Sliding({ styles, editing, ...props }) {
  const Elements = useComponents()
  const [panel, setPanel] = useState(0)
  const panelContainer = useRef(null)
  const panelOne = useRef(null)
  const panelTwo = useRef(null)
  useWindowSize()
  useRerenderOnce()

  let panelStyle = {}
  if (panelContainer.current) {
    if (panel) {
      panelStyle = {
        transform: `translateY(-${
          panelContainer.current.offsetTop + panelOne.current.offsetHeight
        }px)`,
        height: `${panelTwo.current.offsetHeight}px`
      }
    } else {
      panelStyle = {
        transform: `translateY(0)`,
        height: `${panelOne.current.offsetHeight}px`
      }
    }
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", {
        backgroundColor: "#F5F2F2"
      })}
    >
      <div className="formkit-container">
        <div className="formkit-page-header">
          <Elements.Heading
            name="header"
            defaults={{ content: "Enter a catchy page headline" }}
          />
          <Elements.Region name="subheader" className="formkit-subheader">
            <Elements.Content
              defaults={{
                content: "Provide some more detail in this subtitle"
              }}
            />
          </Elements.Region>
        </div>

        <div className="formkit-content">
          <div
            className="formkit-content-background"
            style={styles.forElement("content")}
          />
          <Elements.Form style={styles.forElement("form")}>
            <div
              className="formkit-seymour-panel-container"
              ref={panelContainer}
              style={panelStyle}
            >
              <div
                className="formkit-seymour-panel formkit-seymour-panel-1"
                ref={panelOne}
              >
                <Elements.Content
                  className="formkit-form-header"
                  name="step_header"
                  defaults={{ content: "Describe your product" }}
                />
                <Elements.Region
                  name="step_content"
                  className="formkit-step-content"
                >
                  <Elements.Content
                    defaults={{
                      content:
                        "Add detailed content here. Add detailed content here. Add detailed content here. Add detailed content here. Add detailed content here. Add detailed content here. Add detailed content here."
                    }}
                  />
                </Elements.Region>
                <Elements.Button
                  type="button"
                  input_type="button"
                  name="button"
                  group="step_button"
                  className="formkit-button"
                  defaults={{ content: "Subscribe" }}
                  onClick={() => setPanel(1)}
                />
                {editing && (
                  <button
                    onClick={() => setPanel(1)}
                    type="button"
                    style={{
                      cursor: "pointer",
                      display: "block",
                      margin: "20px auto",
                      fontSize: 18,
                      border: "1px solid #ccc",
                      borderRadius: "3px",
                      padding: "8px 12px",
                      outline: 0
                    }}
                  >
                    Click to edit step 2
                  </button>
                )}
              </div>
              <div
                className="formkit-seymour-panel formkit-seymour-panel-2"
                ref={panelTwo}
              >
                {editing && (
                  <button
                    onClick={() => setPanel(0)}
                    type="button"
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      height: "36px",
                      width: "36px",
                      top: 0,
                      right: 0,
                      borderRadius: "50%",
                      outline: 0
                    }}
                  >
                    ✕
                  </button>
                )}
                <Elements.Heading
                  className="formkit-form-header"
                  name="form_header"
                  defaults={{ content: "Join the newsletter" }}
                />
                <Elements.Content
                  className="formkit-form-subheader"
                  name="form_subheader"
                  defaults={{
                    content: "Subscribe to get our latest content by email"
                  }}
                />
                <Elements.Errors />
                <Elements.CustomFields direction="vertical">
                  <Elements.AddFieldButton />
                  <Elements.Button
                    name="submit"
                    group="button"
                    defaults={{ content: "Subscribe" }}
                  />
                </Elements.CustomFields>
                <Elements.Content
                  name="disclaimer"
                  defaults={{
                    content: "We respect your privacy. Unsubscribe at any time."
                  }}
                />
              </div>
            </div>
          </Elements.Form>
        </div>
      </div>
      <Elements.BuiltWith background="page" />
    </Elements.LandingPage>
  )
}

Sliding.style = "landing_page"
Sliding.categories = ["Event", "Newsletter"]
Sliding.thumbnail = ""
Sliding.preview = "https://pages.convertkit.com/e0208813a4/e2dfb11048"
Sliding.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Sliding, { name: "Sliding" })
